import type { IScale } from "@univerjs/core";
import { numberToABC } from "@univerjs/core";
import type {
  SpreadsheetSkeleton,
  UniverRenderingContext,
} from "@univerjs/engine-render";
import {
  DEFAULT_FONTFACE_PLANE,
  FIX_ONE_PIXEL_BLUR_OFFSET,
  getColor,
  SheetExtension,
} from "@univerjs/engine-render";

const UNIQUE_KEY = "ColumnHeaderCustomExtension";

export class ColumnHeaderCustomExtension extends SheetExtension {
  // TODO: 型を指定
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sheetSchema: any[];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(sheetSchema: any[]) {
    super();
    this.sheetSchema = sheetSchema;
  }

  override uKey = UNIQUE_KEY;

  // Must be greater than 10
  override get zIndex() {
    return 11;
  }

  override draw(
    ctx: UniverRenderingContext,
    parentScale: IScale,
    spreadsheetSkeleton: SpreadsheetSkeleton
  ) {
    const { rowColumnSegment, columnHeaderHeight = 0 } = spreadsheetSkeleton;
    const { startColumn, endColumn } = rowColumnSegment;

    if (!spreadsheetSkeleton) {
      return;
    }

    // レンダリングするシートのIDから、設定するスキーマを変更
    const sheetId = spreadsheetSkeleton.worksheet?.getSheetId();
    const renderingSheetSchema = this.sheetSchema.filter(
      (schema) => schema.sheet_id === sheetId
    );

    const {
      rowHeightAccumulation,
      columnTotalWidth,
      columnWidthAccumulation,
      rowTotalHeight,
    } = spreadsheetSkeleton;

    if (
      !rowHeightAccumulation ||
      !columnWidthAccumulation ||
      columnTotalWidth === undefined ||
      rowTotalHeight === undefined
    ) {
      return;
    }

    const scale = this._getScale(parentScale);

    // painting background
    ctx.fillStyle = getColor([248, 249, 250])!;
    ctx.fillRectByPrecision(0, 0, columnTotalWidth, columnHeaderHeight);

    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillStyle = getColor([0, 0, 0])!;
    ctx.beginPath();
    ctx.setLineWidthByPrecision(1);

    ctx.translateWithPrecisionRatio(
      FIX_ONE_PIXEL_BLUR_OFFSET,
      FIX_ONE_PIXEL_BLUR_OFFSET
    );

    ctx.strokeStyle = getColor([217, 217, 217])!;
    ctx.font = `13px ${DEFAULT_FONTFACE_PLANE}`;
    let preColumnPosition = 0;
    const columnWidthAccumulationLength = columnWidthAccumulation.length;

    // schemasをcolmun_indexでソートする
    const sortedSchemas = renderingSheetSchema.sort(
      (a: { column_index: number }, b: { column_index: number }) => {
        return a.column_index - b.column_index;
      }
    );

    for (let c = startColumn - 1; c <= endColumn; c++) {
      if (c < 0 || c > columnWidthAccumulationLength - 1) {
        continue;
      }

      const columnEndPosition = columnWidthAccumulation[c];
      if (preColumnPosition === columnEndPosition) {
        // Skip hidden columns
        continue;
      }

      // painting line border
      ctx.moveToByPrecision(columnEndPosition, 0);
      ctx.lineToByPrecision(columnEndPosition, columnHeaderHeight);

      // painting column header text
      const middleCellPos =
        preColumnPosition + (columnEndPosition - preColumnPosition) / 2;

      // デフォルトの列名と異なっていたら、列名を変更
      if (sortedSchemas[c] && sortedSchemas[c].title !== numberToABC(c)) {
        const columnString = `${sortedSchemas[c].title} (${numberToABC(c)})`;
        ctx.fillText(columnString, middleCellPos, columnHeaderHeight / 2);
      } else {
        ctx.fillText(numberToABC(c), middleCellPos, columnHeaderHeight / 2);
      }
      preColumnPosition = columnEndPosition;
    }

    // painting line bottom border
    const columnHeaderHeightFix = columnHeaderHeight - 0.5 / scale;
    ctx.moveToByPrecision(0, columnHeaderHeightFix);
    ctx.lineToByPrecision(columnTotalWidth, columnHeaderHeightFix);
    ctx.stroke();
  }
}
