import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "../../supabaseClient";
import { Box } from "@chakra-ui/react";

type Props = {
  component: React.ReactNode;
  redirect: string;
};

// 参考: https://zenn.dev/longbridge/articles/61b05d8bdb014d
export const RouteAuthGuard: React.VFC<Props> = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const {
        data: { session: session },
        error,
      } = await supabase.auth.getSession();
      if (error) {
        console.error(error);
        return;
      }

      if (!session) {
        navigate(props.redirect);
        return;
      }

      setIsLoading(false);
    })();
  }, [navigate, props.redirect]);

  if (isLoading) {
    return <Box>Loading...</Box>;
  }

  return <>{props.component}</>;
};
