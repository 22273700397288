import "@univerjs/design/lib/index.css";
import "@univerjs/ui/lib/index.css";
import "@univerjs/sheets-ui/lib/index.css";
import "@univerjs/sheets-formula/lib/index.css";

import {
  Univer,
  LocaleType,
  UniverInstanceType,
  Tools,
  IWorkbookData,
} from "@univerjs/core";
import { defaultTheme } from "@univerjs/design";
import { UniverDocsPlugin } from "@univerjs/docs";
import { UniverDocsUIPlugin } from "@univerjs/docs-ui";
import { UniverFormulaEnginePlugin } from "@univerjs/engine-formula";
import { UniverRenderEnginePlugin } from "@univerjs/engine-render";
import { UniverSheetsPlugin } from "@univerjs/sheets";
import { UniverSheetsFormulaPlugin } from "@univerjs/sheets-formula";
import {
  UniverSheetsUIPlugin,
  AddWorksheetMergeCommand,
} from "@univerjs/sheets-ui";
import { UniverUIPlugin } from "@univerjs/ui";
import { FUniver } from "@univerjs/facade";
import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import DesignEnUS from "@univerjs/design/locale/en-US";
import UIEnUS from "@univerjs/ui/locale/en-US";
import DocsUIEnUS from "@univerjs/docs-ui/locale/en-US";
import SheetsEnUS from "@univerjs/sheets/locale/en-US";
import SheetsUIEnUS from "@univerjs/sheets-ui/locale/en-US";
import { ColumnHeaderCustomExtension } from "./column-header-extension";
import { Project, SheetSchema } from "../../../supabase/schema/types";
import {
  HandleInsertColType,
  HandleMoveColType,
  HandleRemoveColType,
} from "../pages/project";

type UniverSheetProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  sheetProject: Project;
  sheetSchemas: SheetSchema[];
  handleExecuteCommand: (snapshot: IWorkbookData) => void;
  setActiveSheetId: (sheetId: string) => void;
  setSheetSchema: (sheetSchemas: SheetSchema[]) => void;
  handleInsertCol: HandleInsertColType;
  handleMoveCol: HandleMoveColType;
  handleRemoveCol: HandleRemoveColType;
};

const UniverSheet = forwardRef((props: UniverSheetProps, ref) => {
  const univerRef = useRef<Univer | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const workbookRef = useRef<any>(null);
  const containerRef = useRef(null);
  const fUniverRef = useRef<FUniver | null>(null);

  useImperativeHandle(ref, () => ({
    getData,
    getUniverRef,
    univerAPI: fUniverRef,
  }));

  /**
   * Destroy univer instance and workbook instance
   */
  const destroyUniver = () => {
    // univerRef.current?.dispose();
    univerRef.current = null;
    workbookRef.current = null;
  };

  /**
   * Get workbook data
   */
  const getData = () => {
    if (!workbookRef.current) {
      throw new Error("Workbook is not initialized");
    }
    return workbookRef.current.save();
  };

  const getUniverRef = () => {
    return univerRef.current;
  };

  /**
   * Initialize univer instance and workbook instance
   * @param data {IWorkbookData} document see https://univer.work/api/core/interfaces/IWorkbookData.html
   */
  // const init = (data = {}, sheetSchemas: SheetSchema[]) => {
  const init = (data = {}) => {
    if (!containerRef.current) {
      throw Error("container not initialized");
    }
    const univer = new Univer({
      theme: defaultTheme,
      locale: LocaleType.EN_US,
      locales: {
        [LocaleType.EN_US]: Tools.deepMerge(
          SheetsEnUS,
          DocsUIEnUS,
          SheetsUIEnUS,
          UIEnUS,
          DesignEnUS
        ),
      },
    });
    univerRef.current = univer;

    // core plugins
    univer.registerPlugin(UniverRenderEnginePlugin);
    univer.registerPlugin(UniverFormulaEnginePlugin);
    univer.registerPlugin(UniverUIPlugin, {
      container: containerRef.current,
    });

    // doc plugins
    univer.registerPlugin(UniverDocsPlugin, {
      hasScroll: false,
    });
    univer.registerPlugin(UniverDocsUIPlugin);

    // sheet plugins
    univer.registerPlugin(UniverSheetsPlugin);
    univer.registerPlugin(UniverSheetsUIPlugin, {
      menu: {
        // マージ機能を非表示にする
        [AddWorksheetMergeCommand.id]: {
          hidden: true,
        },
      },
    });
    univer.registerPlugin(UniverSheetsFormulaPlugin);

    // create workbook instance
    univer.createUnit(UniverInstanceType.UNIVER_SHEET, data);

    // craete Facade API instance
    fUniverRef.current = FUniver.newAPI(univer);

    const unitId = "workbook";

    const univerAPI = FUniver.newAPI(univer);

    // カスタムヘッダーを設定
    univerAPI.getHooks().onRendered(() => {
      univerAPI.registerSheetColumnHeaderExtension(
        unitId,
        new ColumnHeaderCustomExtension(props.sheetSchemas)
      );
    });
  };

  useEffect(() => {
    init(props.data);

    if (fUniverRef.current) {
      fUniverRef.current.onCommandExecuted((command) => {
        const unitId = "workbook";
        const activeWorkbook = fUniverRef.current?.getActiveWorkbook();

        if (activeWorkbook && fUniverRef.current) {
          // 更新対象のスキーマを切り替えるため、表示中のシートのIDを取得
          props.setActiveSheetId(
            activeWorkbook.getActiveSheet()?.getSheetId() || ""
          );

          const snapshot = activeWorkbook.save();

          // 列が入れ替えられた時
          if (command.id === "sheet.mutation.move-columns") {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const startColIndex = (command.params as any)?.sourceRange
              ?.startColumn;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const endColIndex = (command.params as any)?.sourceRange?.endColumn;

            // 挿入先のインデックス
            let targetColIndex =
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (command.params as any)?.targetRange?.startColumn - 1;
            if (targetColIndex > startColIndex) {
              targetColIndex -= endColIndex - startColIndex + 1;
            }

            props.handleMoveCol(
              startColIndex,
              endColIndex,
              targetColIndex,
              activeWorkbook,
              fUniverRef.current,
              unitId
            );
          }

          // 列が追加された時
          if (command.id === "sheet.mutation.insert-col") {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const startColIndex = (command.params as any)?.range?.startColumn;

            props.handleInsertCol(
              startColIndex,
              activeWorkbook,
              fUniverRef.current,
              unitId
            );
          }

          // 列が削除された時
          if (command.id === "sheet.mutation.remove-col") {
            // カラム名の表示を修正するため、sheetSchemasを更新する
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const startColIndex = (command.params as any)?.range?.startColumn;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const endColIndex = (command.params as any)?.range?.endColumn;

            props.handleRemoveCol(
              startColIndex,
              endColIndex,
              activeWorkbook,
              fUniverRef.current,
              unitId
            );
          }

          // シートが更新された場合
          // シートの追加・削除なども含む
          if (
            command.type === 2 &&
            // 以下のコマンドは、DBで保持すべきシートのデータが更新されたわけではないので、無視する
            ![
              "formula.mutation.set-formula-calculation-start",
              "formula.mutation.set-formula-calculation-notification",
              "formula.mutation.set-formula-data",
              "doc.mutation.rich-text-editing",
            ].includes(command.id)
          ) {
            props.handleExecuteCommand(snapshot);
          }
        }
      });
    }

    return () => {
      destroyUniver();
    };
    // FIXME: props.sheetSchemasが更新される度にリロードが走ってしまう。
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, props.sheetSchemas]);

  return <div ref={containerRef} className="univer-container" />;
});

export default UniverSheet;
