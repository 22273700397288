import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SignIn from "./components/pages/sign-in";
import SignUp from "./components/pages/sign-up";
import EmailComfirmation from "./components/pages/sign-up/email-comfirmation";
import Dashboard from "./components/pages/dashboard";
import Project from "./components/pages/project";
import { RouteAuthGuard } from "./components/RouteAuthGuard";
import SignOut from "./components/pages/sign-out";

export const RouterConfig: React.VFC = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            index
            element={
              <RouteAuthGuard component={<Dashboard />} redirect="/sign-in" />
            }
          />
          {/* TODO: ログインしてたらリダイレクト */}
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/email-comfirmation" element={<EmailComfirmation />} />
          <Route path="/sign-out" element={<SignOut />} />

          <Route
            path="project/:projectId"
            element={
              <RouteAuthGuard component={<Project />} redirect="/sign-in" />
            }
          ></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};
