import {
  Box,
  Card,
  CardBody,
  Center,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/react";

export default function EmailComfirmation() {
  return (
    <Box py="12">
      <VStack>
        <Heading fontSize={"4xl"}>
          Kurage
          <span
            style={{
              marginTop: "1.0em",
              fontSize: "0.5em",
              marginLeft: "0.5em",
              fontWeight: "bold",
            }}
          >
            ベータ版
          </span>
        </Heading>
        <Center mt="50">
          <Card>
            <CardBody>
              <Text>
                ご入力いただいたメールアドレス宛に、確認メールを送信いたしました。
                <br />
                ご確認ください。
              </Text>
            </CardBody>
          </Card>
        </Center>
      </VStack>
    </Box>
  );
}
