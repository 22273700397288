import { ReactNode, useState } from "react";
import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Link,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Button,
  Center,
} from "@chakra-ui/react";
import { FiHome, FiMenu } from "react-icons/fi";
import { HiMiniWrenchScrewdriver } from "react-icons/hi2";

import { GrCircleQuestion } from "react-icons/gr";
import { IconType } from "react-icons";
import { supabase } from "../../supabaseClient";
import { useNavigate } from "react-router-dom";

interface LinkItemProps {
  key: string;
  displayName: string;
  icon: IconType;
}
const LinkItems: Array<LinkItemProps> = [
  { key: "home", displayName: "ホーム", icon: FiHome },
  { key: "help", displayName: "ヘルプ", icon: GrCircleQuestion },
];

export default function SidebarWithHeader({
  homeChildren,
  helpChildren,
}: {
  homeChildren: ReactNode;
  helpChildren: ReactNode;
}) {
  // FIXME: ステートなどは親コンポーネントに持たせたい
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedSidebarItem, setSelectedSidebarItem] = useState("home");
  const navigate = useNavigate();

  const handleSignOut = async () => {
    await supabase.auth.signOut();

    navigate("/sign-in");
  };

  return (
    <Box minH="100vh" w="100%" bg={useColorModeValue("white.100", "white.900")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
        setSelectedSidebarItem={setSelectedSidebarItem}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent
            onClose={onClose}
            setSelectedSidebarItem={setSelectedSidebarItem}
          />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} handleSignOut={handleSignOut} />
      <Box ml={{ base: 0, md: 60 }} h="100%">
        {selectedSidebarItem === "home" && homeChildren}
        {selectedSidebarItem === "help" && helpChildren}
      </Box>
    </Box>
  );
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
  setSelectedSidebarItem: React.Dispatch<React.SetStateAction<string>>;
}

const SidebarContent = ({
  onClose,
  setSelectedSidebarItem,
  ...rest
}: SidebarProps) => {
  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue("gray.100", "gray.900")}
      borderRightWidth="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Text fontSize="2xl" fontWeight="bold">
          Kurage
          <span
            style={{
              marginTop: "1.0em",
              fontSize: "0.5em",
              marginLeft: "0.5em",
              fontWeight: "bold",
            }}
          >
            ベータ版
          </span>
        </Text>
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link) => (
        <NavItem
          key={link.key}
          itemName={link.key}
          icon={link.icon}
          setSelectedSidebarItem={setSelectedSidebarItem}
        >
          {link.displayName}
        </NavItem>
      ))}
      <ContactButton />
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  icon: IconType;
  itemName: string;
  setSelectedSidebarItem: React.Dispatch<React.SetStateAction<string>>;
}
const NavItem = ({
  itemName,
  icon,
  setSelectedSidebarItem,
  children,
  ...rest
}: NavItemProps) => {
  return (
    <Link
      style={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
      onClick={() => setSelectedSidebarItem(itemName)}
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: "blue.400",
          color: "white",
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "white",
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

const ContactButton = () => {
  const handleClick = () => {
    window.location.href = "https://www.example.com/";
  };

  return (
    <Center>
      <Box
        position="absolute"
        bottom="4"
        width="180px"
        height="200px"
        rounded="lg"
        bg="blue.400"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <VStack spacing={4}>
          <HiMiniWrenchScrewdriver size={60} color="white" />
          <Text
            px={4}
            fontSize="sm"
            fontWeight="bold"
            color="white"
            textAlign="center"
          >
            利用シーンに合わせて
            <br />
            カスタマイズ可能です
          </Text>
          <Button size="sm" onClick={handleClick}>
            詳しくはこちら
          </Button>
        </VStack>
      </Box>
    </Center>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
  handleSignOut: () => void;
}
const MobileNav = ({ onOpen, handleSignOut, ...rest }: MobileProps) => {
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("gray.100", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent={{ base: "space-between", md: "flex-end" }}
      {...rest}
    >
      <IconButton
        display={{ base: "flex", md: "none" }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Text
        display={{ base: "flex", md: "none" }}
        fontSize="2xl"
        fontWeight="bold"
      >
        Kurage{" "}
        <span
          style={{
            marginTop: "1.0em",
            fontSize: "0.5em",
            marginLeft: "0.5em",
            fontWeight: "bold",
          }}
        >
          ベータ版
        </span>
      </Text>

      <HStack spacing={{ base: "0", md: "6" }}>
        <Flex alignItems={"center"}>
          <Menu>
            <MenuButton
              py={2}
              px={6}
              transition="all 0.3s"
              _focus={{ boxShadow: "none" }}
            >
              <Avatar size={"sm"} bgColor={"cyan.500"} />
            </MenuButton>
            <MenuList
              bg={useColorModeValue("gray.100", "gray.900")}
              borderColor={useColorModeValue("gray.200", "gray.700")}
            >
              <MenuItem onClick={() => handleSignOut()}>ログアウト</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};
