import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  Spinner,
  Center,
  useToast,
  Checkbox,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { supabase } from "../../../supabaseClient";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";

type FormValues = {
  email: string;
  password: string;
  passwordComfirmation: string;
  privacyPolicy: boolean;
  termsOfService: boolean;
};

export default function SignUp() {
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormValues>();
  const [privacyPolicy, termsOfService] = watch([
    "privacyPolicy",
    "termsOfService",
  ]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();
  const toast = useToast();
  const colorModeValue = useColorModeValue("white", "gray.700");

  const onSubmit = async (formValues: FormValues) => {
    setIsSubmitting(true);
    try {
      const { error: signUpError } = await supabase.auth.signUp({
        email: formValues.email,
        password: formValues.password,
      });

      if (signUpError) {
        throw new Error(signUpError.message);
      }

      navigate("/email-comfirmation");
    } catch (error) {
      toast({
        title: "エラー",
        description: "新規登録に失敗しました。時間をおいて再度お試しください。",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    (async () => {
      const {
        data: { session: session },
        error,
      } = await supabase.auth.getSession();

      if (error) {
        console.error(error);
        return;
      }

      if (session) {
        navigate("/");
        return;
      }

      setIsLoading(false);
    })();
  }, [navigate]);

  if (isLoading) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  return (
    <Flex align={"center"} justify={"center"}>
      <Stack spacing={8} mx={"auto"} minW={"60%"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <Heading fontSize={"4xl"}>
            Kurage
            <span
              style={{
                marginTop: "1.0em",
                fontSize: "0.5em",
                marginLeft: "0.5em",
                fontWeight: "bold",
              }}
            >
              ベータ版
            </span>
          </Heading>
          <Text fontSize={"lg"} color={"gray.600"}>
            新規登録
          </Text>
        </Stack>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            rounded={"lg"}
            bg={colorModeValue}
            boxShadow={"lg"}
            p={8}
            maxW={"2xl"}
            mx={"auto"}
          >
            <Stack>
              <FormControl id="email">
                <FormLabel>メールアドレス</FormLabel>
                <Input
                  {...register("email", {
                    required: "この項目は必須入力です。",
                  })}
                  type="email"
                  autoComplete="email"
                />

                {errors.email && (
                  <Text color="red">{errors.email.message?.toString()}</Text>
                )}
              </FormControl>

              <FormControl id="password" mt={4}>
                <FormLabel>パスワード</FormLabel>
                <Input
                  {...register("password", {
                    required: "この項目は必須入力です。",
                    minLength: {
                      value: 8,
                      message: "パスワードは8文字以上で入力してください。",
                    },
                  })}
                  placeholder="パスワードは8文字以上で入力してください。"
                  type="password"
                  autoComplete="new-password"
                />

                {errors.password && (
                  <Text color="red">{errors.password.message?.toString()}</Text>
                )}
              </FormControl>

              <FormControl id="passwordComfirmation" mt={4}>
                <FormLabel>パスワード（再入力）</FormLabel>
                <Input
                  {...register("passwordComfirmation", {
                    required: "この項目は必須入力です。",
                    minLength: {
                      value: 8,
                      message: "パスワードは8文字以上で入力してください。",
                    },
                    validate: (value) =>
                      value === getValues("password") ||
                      "パスワードが一致しません。",
                  })}
                  placeholder="パスワードは8文字以上で入力してください。"
                  type="password"
                  autoComplete="new-password"
                />

                {errors.passwordComfirmation && (
                  <Text color="red">
                    {errors.passwordComfirmation.message?.toString()}
                  </Text>
                )}
              </FormControl>

              <FormControl id="termsOfService" mt={8}>
                <Stack spacing={5} direction="row">
                  <Checkbox
                    {...register("termsOfService", {
                      required: "この項目は必須入力です。",
                    })}
                    type="termsOfService"
                    onChange={(e) => {
                      setValue("termsOfService", e.target.checked);
                    }}
                  >
                    <Box
                      as="a"
                      href="https://www.kurage-sales.com/terms-of-service"
                      target="_blank"
                      color="blue.400"
                      textDecoration="underline"
                    >
                      利用規約
                    </Box>
                    に同意
                  </Checkbox>
                </Stack>

                {errors.termsOfService && (
                  <Text color="red">
                    {errors.termsOfService.message?.toString()}
                  </Text>
                )}
              </FormControl>
              <FormControl id="privacyPolicy">
                <Stack spacing={5} direction="row">
                  <Checkbox
                    {...register("privacyPolicy", {
                      required: "この項目は必須入力です。",
                    })}
                    type="privacyPolicy"
                    onChange={(e) => {
                      setValue("privacyPolicy", e.target.checked);
                    }}
                  >
                    <Box
                      as="a"
                      href="https://www.kurage-sales.com/privacy-policy"
                      target="_blank"
                      color="blue.400"
                      textDecoration="underline"
                    >
                      プライバシーポリシー
                    </Box>
                    に同意
                  </Checkbox>
                </Stack>

                {errors.privacyPolicy && (
                  <Text color="red">
                    {errors.privacyPolicy.message?.toString()}
                  </Text>
                )}
              </FormControl>

              <Stack spacing={10} mt={4}>
                <Button
                  bg={"blue.400"}
                  color={"white"}
                  _hover={{
                    bg: "blue.500",
                  }}
                  type="submit"
                  isDisabled={!privacyPolicy || !termsOfService}
                  isLoading={isSubmitting}
                >
                  アカウントを作成
                </Button>
              </Stack>
            </Stack>
            <Text
              align={"center"}
              fontSize={"sm"}
              mt={4}
              _hover={{ color: "blue.400" }}
            >
              <a href="/sign-in" color={"blue.400"}>
                すでにアカウントをお持ちですか？
              </a>
            </Text>
          </Box>
        </form>
      </Stack>
    </Flex>
  );
}
