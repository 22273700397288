import { useEffect, useRef, useState } from "react";
import UniverSheet from "../../UniverSheet";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Input,
  Spinner,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  FormLabel,
  Stack,
  FormControl,
  Checkbox,
  Divider,
  UnorderedList,
  ListItem,
  useToast,
} from "@chakra-ui/react";
import KurageIcon from "../../../assets/icon-kurage.jpg";
import "./index.css";
import type { Project, SheetSchema } from "../../../../supabase/schema/types";
import { supabase } from "../../../supabaseClient";
import { IWorkbookData, numberToABC } from "@univerjs/core";
import { RiSettings5Fill } from "react-icons/ri";
import { FaSave } from "react-icons/fa";
import { WarningIcon } from "@chakra-ui/icons";
import {
  useForm,
  SubmitHandler,
  useFormContext,
  FormProvider,
  useFieldArray,
  FieldArrayWithId,
} from "react-hook-form";
import React from "react";
import { FUniver, FWorkbook } from "@univerjs/facade";
import { ColumnHeaderCustomExtension } from "../../UniverSheet/column-header-extension";
import { Helmet } from "react-helmet-async";
import * as Sentry from "@sentry/react";

type Param = {
  projectId?: string;
};

type InvalidCell = {
  rowIndex: number;
  colIndex: number;
  reason: string;
};

export type HandleInsertColType = (
  startColIndex: number,
  activeWorkbook: FWorkbook,
  univerAPI: FUniver,
  unitId: string
) => void;

export type HandleMoveColType = (
  startColIndex: number,
  endColIndex: number,
  targetColIndex: number,
  activeWorkbook: FWorkbook,
  univerAPI: FUniver,
  unitId: string
) => void;

export type HandleRemoveColType = (
  startColIndex: number,
  endColIndex: number,
  activeWorkbook: FWorkbook,
  univerAPI: FUniver,
  unitId: string
) => void;

interface IFormInput {
  items: {
    id: number;
    colName: string;
    // colDataType: string;
    isRequired: boolean;
  }[];
}

function SchemaSettingDrawer(props: {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  fields: FieldArrayWithId<IFormInput, "items", "id">[];
  project: Project;
}) {
  const {
    register,
    formState: { errors },
  } = useFormContext<IFormInput>();

  return (
    <Drawer
      isOpen={props.isOpen}
      placement="right"
      size="lg"
      onClose={props.onClose}
    >
      <DrawerOverlay />
      <DrawerContent overflow="scroll">
        <form onSubmit={props.onSubmit}>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">ルール設定</DrawerHeader>

          <DrawerBody>
            <Stack spacing="24px">
              {props.fields.map((field, index) => (
                <React.Fragment key={field.id}>
                  <FormControl
                    id={`id-${index}`}
                    isInvalid={!!errors.items?.[index]?.id}
                  >
                    <Input type="hidden" {...register(`items.${index}.id`)} />
                  </FormControl>

                  <FormControl
                    id={`colName-${index}`}
                    isInvalid={!!errors.items?.[index]?.colName}
                  >
                    <FormLabel htmlFor="colName">
                      列名 ({numberToABC(index)})
                    </FormLabel>
                    <Input
                      {...register(`items.${index}.colName`, {
                        required: "This is required",
                      })}
                    />
                    {errors.items?.[index]?.colName && (
                      <span>{errors.items[index].colName?.message}</span>
                    )}
                  </FormControl>
                  {/* <FormControl
                    id={`colDataType-${index}`}
                    isInvalid={!!errors.items?.[index]?.colDataType}
                  >
                    <FormLabel htmlFor="colDataType">データ型</FormLabel>
                    <Select {...register(`items.${index}.colDataType`)}>
                      <option value="">Select option</option>
                      <option value="option1">Option 1</option>
                      <option value="option2">Option 2</option>
                      <option value="option3">Option 3</option>
                    </Select>
                    {errors.items?.[index]?.colDataType && (
                      <span>{errors.items[index].colDataType?.message}</span>
                    )}
                  </FormControl> */}
                  <FormControl
                    id={`isRequired-${index}`}
                    isInvalid={!!errors.items?.[index]?.isRequired}
                  >
                    <FormLabel>オプション</FormLabel>
                    <Checkbox {...register(`items.${index}.isRequired`)}>
                      入力を必須にする
                    </Checkbox>
                  </FormControl>
                  <Button
                    colorScheme="teal"
                    type="submit"
                    w="15%"
                    ml="auto"
                    mr="6"
                  >
                    保存
                  </Button>

                  <Divider borderColor="gray.500" />
                </React.Fragment>
              ))}
            </Stack>
          </DrawerBody>
        </form>
      </DrawerContent>
    </Drawer>
  );
}

function SchemaAlertDrawer(props: {
  isOpen: boolean;
  onClose: () => void;
  invalidCells: InvalidCell[];
}) {
  return (
    <Drawer
      isOpen={props.isOpen}
      placement="right"
      size="lg"
      onClose={props.onClose}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="1px">ルール違反</DrawerHeader>
        <DrawerBody>
          <UnorderedList>
            {props.invalidCells.map((cell, index) => (
              <ListItem key={index}>
                {cell.rowIndex + 1}行{numberToABC(cell.colIndex)}列:{" "}
                {cell.reason}
              </ListItem>
            ))}
          </UnorderedList>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

const checkIsSchemaViolated = (
  sheetSchemas: SheetSchema[],
  workbookDataSnapshot: IWorkbookData,
  setInvalidCells: React.Dispatch<React.SetStateAction<InvalidCell[]>>
): boolean => {
  let isSchemaViolated = false;

  // TODO: 外に出す
  setInvalidCells([]);

  Object.keys(workbookDataSnapshot.sheets).forEach((sheetId) => {
    const targetSheetCellData = workbookDataSnapshot.sheets[sheetId].cellData;
    if (!targetSheetCellData) return;

    const targetSheetSchema = sheetSchemas
      .filter((schema) => schema.sheet_id === sheetId)
      .sort((a, b) => a.column_index - b.column_index);

    const requiredColIndex = targetSheetSchema
      .filter((schema) => schema.is_required)
      .map((schema) => schema.column_index);

    Object.keys(targetSheetCellData).forEach((rowIndex) => {
      const row = targetSheetCellData[Number(rowIndex)];

      // is_requiredがtrueの列に値が入っていないのに、他の列に値が入っている場合
      if (Object.values(row).some((value) => !!value?.v)) {
        requiredColIndex.forEach((colIndex) => {
          if (!row[colIndex] || !row[colIndex].v) {
            isSchemaViolated = true;

            setInvalidCells((prev) => [
              ...prev,
              {
                rowIndex: Number(rowIndex),
                colIndex,
                reason: "必須項目が入力されていません",
              },
            ]);
          }
        });
      }

      // TODO: スキーマに定義されたデータ型に反している値が入っている場合
      // xxx
    });
  });

  return isSchemaViolated;
};

export default function Project() {
  const params: Param = useParams<Param>();
  const navigate = useNavigate();

  const {
    isOpen: isSchemaSettingDrawerOpen,
    onOpen: onSchemaSettingDrawerOpen,
    onClose: onSchemaSettingDrawerClose,
  } = useDisclosure();
  const {
    isOpen: isSchemaAlertDrawerOpen,
    onOpen: onSchemaAlertDrawerOpen,
    onClose: onSchemaAlertDrawerClose,
  } = useDisclosure();
  const methods = useForm<IFormInput>({});
  const { fields } = useFieldArray({
    control: methods.control,
    name: "items",
  });

  const univerRef = useRef();

  const [project, setProject] = useState<Project>();
  const [sheetSchemas, setSheetSchemas] = useState<SheetSchema[]>();
  const [workbookData, setWorkbookData] = useState<IWorkbookData>();

  const [shouldSave, setShouldSave] = useState(false);
  const [isSchemaViolated, setIsSchemaViolated] = useState(false);
  const [invalidCells, setInvalidCells] = useState<InvalidCell[]>([]);
  const [workbookDataSnapshot, setWorkbookDataSnapshot] =
    useState<IWorkbookData>();

  const [activeSheetId, setActiveSheetId] = useState<string>("");
  const toast = useToast();

  const fetchSheetSchemas = async (projectId: string) => {
    try {
      1 / 0;
      const { data, error } = await supabase
        .from("sheet_schema")
        .select("*")
        .eq("project_id", projectId);

      if (error) {
        throw new Error(error.message);
      }

      setSheetSchemas(data);
      return data;
    } catch (error) {
      toast({
        title: "エラー",
        description:
          "データを取得できませんでした。時間をおいて再度お試しください。",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
      Sentry.captureException(error);
    }
  };

  const handleTitleChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setProject((prev) => {
      if (!prev) return prev;

      return {
        ...prev,
        title: event.target.value,
      };
    });

    setShouldSave(true);
  };

  const handleExecuteCommand = (snapshot: IWorkbookData) => {
    // FIXME: 初期値と差分がなければ、保存ボタンを無効化する
    setShouldSave(true);
    setWorkbookDataSnapshot(snapshot);

    const isSchemaViolated = checkIsSchemaViolated(
      sheetSchemas || [],
      snapshot,
      setInvalidCells
    );
    setIsSchemaViolated(isSchemaViolated);
  };

  const handleInsertCol: HandleInsertColType = (
    startColIndex,
    activeWorkbook,
    univerAPI,
    unitId
  ) => {
    // カラム名の表示を修正するため、sheetSchemasを更新する
    const targetSheetSchemas = sheetSchemas
      ?.filter(
        (schema: SheetSchema) =>
          schema.sheet_id === activeWorkbook.getActiveSheet()?.getSheetId()
      )
      .sort(
        (a: SheetSchema, b: SheetSchema) => a.column_index - b.column_index
      );

    const otherSheetSchemas = sheetSchemas?.filter(
      (schema) =>
        schema.sheet_id !== activeWorkbook.getActiveSheet()?.getSheetId()
    );

    const activeSheet = activeWorkbook.getActiveSheet();
    if (!activeSheet) return;

    targetSheetSchemas?.splice(startColIndex, 0, {
      title: numberToABC(startColIndex),
      sheet_id: activeSheet.getSheetId(),
      project_id: project?.id,
      is_required: false,
    } as SheetSchema);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    targetSheetSchemas?.forEach((schema: any, index: any) => {
      schema.column_index = index;
    });

    // カラム名がデフォルトだった場合、index更新後のカラムのデフォルト値を設定する
    targetSheetSchemas?.forEach((schema, index) => {
      if (index <= startColIndex) return;

      if (schema.title === numberToABC(index - 1)) {
        schema.title = numberToABC(index);
      }
    });

    univerAPI.registerSheetColumnHeaderExtension(
      unitId,
      new ColumnHeaderCustomExtension([
        ...(otherSheetSchemas || []),
        ...(targetSheetSchemas || []),
      ])
    );

    setSheetSchemas([
      ...(otherSheetSchemas || []),
      ...(targetSheetSchemas || []),
    ]);
  };

  const handleMoveCol: HandleMoveColType = (
    startColIndex,
    endColIndex,
    targetColIndex,
    activeWorkbook,
    univerAPI,
    unitId
  ) => {
    const targetSheetSchemas = sheetSchemas
      ?.filter(
        (schema: SheetSchema) =>
          schema.sheet_id === activeWorkbook.getActiveSheet()?.getSheetId()
      )
      .sort(
        (a: SheetSchema, b: SheetSchema) => a.column_index - b.column_index
      );
    const otherSheetSchemas = sheetSchemas?.filter(
      (schema) =>
        schema.sheet_id !== activeWorkbook.getActiveSheet()?.getSheetId()
    );

    const movedColumns = targetSheetSchemas?.splice(
      startColIndex,
      endColIndex - startColIndex + 1
    );

    targetSheetSchemas?.splice(targetColIndex + 1, 0, ...(movedColumns || []));

    // カラム名がデフォルトだった場合、index更新後のカラムのデフォルト値を設定する
    targetSheetSchemas?.forEach((schema, index) => {
      if (schema.title === numberToABC(schema.column_index)) {
        schema.title = numberToABC(index);
      }
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    targetSheetSchemas?.forEach((schema: any, index: any) => {
      schema.column_index = index;
    });

    univerAPI.registerSheetColumnHeaderExtension(
      unitId,
      new ColumnHeaderCustomExtension([
        ...(otherSheetSchemas || []),
        ...(targetSheetSchemas || []),
      ])
    );

    setSheetSchemas([
      ...(otherSheetSchemas || []),
      ...(targetSheetSchemas || []),
    ]);
  };

  const handleRemoveCol: HandleRemoveColType = (
    startColIndex,
    endColIndex,
    activeWorkbook,
    univerAPI,
    unitId
  ) => {
    const targetSheetSchemas = sheetSchemas
      ?.filter(
        (schema: SheetSchema) =>
          schema.sheet_id === activeWorkbook.getActiveSheet()?.getSheetId()
      )
      .sort(
        (a: SheetSchema, b: SheetSchema) => a.column_index - b.column_index
      );
    const otherSheetSchemas = sheetSchemas?.filter(
      (schema) =>
        schema.sheet_id !== activeWorkbook.getActiveSheet()?.getSheetId()
    );

    targetSheetSchemas?.splice(startColIndex, endColIndex - startColIndex + 1);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    targetSheetSchemas?.forEach((schema: any, index: any) => {
      schema.column_index = index;
    });

    // カラム名がデフォルトだった場合、index更新後のカラムのデフォルト値を設定する
    targetSheetSchemas?.forEach((schema, index) => {
      if (index <= startColIndex - 1) return;

      if (schema.title === numberToABC(index + 1)) {
        schema.title = numberToABC(index);
      }
    });

    univerAPI.registerSheetColumnHeaderExtension(
      unitId,
      new ColumnHeaderCustomExtension([
        ...(otherSheetSchemas || []),
        ...(targetSheetSchemas || []),
      ])
    );

    setSheetSchemas([
      ...(otherSheetSchemas || []),
      ...(targetSheetSchemas || []),
    ]);
  };

  const handleOnClickSave = async () => {
    if (!workbookDataSnapshot) return;
    try {
      const { error: updateSheetProjectError } = await supabase
        .from("project")
        .update({
          title: project?.title,
          workbook_data: workbookDataSnapshot,
        })
        .eq("id", project?.id);

      if (updateSheetProjectError) {
        throw new Error(updateSheetProjectError.message);
      }

      // スキーマの更新
      const { error: updateSheetSchemaError } = await supabase
        .from("sheet_schema")
        .upsert(
          sheetSchemas?.filter((schema) => schema.id),
          { onConflict: "id" }
        );

      if (updateSheetSchemaError) {
        throw new Error(updateSheetSchemaError.message);
      }

      // スキーマの追加
      const { data: createSheetSchemaData, error: createSheetSchemaError } =
        await supabase
          .from("sheet_schema")
          .insert(sheetSchemas?.filter((schema) => !schema.id))
          .select()
          .returns<SheetSchema[]>();

      if (createSheetSchemaError) {
        throw new Error(createSheetSchemaError.message);
      }

      // スキーマの削除
      const { data: selectSheetSchemaData, error: selectSheetSchemaError } =
        await supabase
          .from("sheet_schema")
          .select("*")
          .eq("project_id", project?.id);

      if (selectSheetSchemaError) {
        throw new Error(selectSheetSchemaError.message);
      }

      let deletedSheetSchemaIds: number[] = [];
      selectSheetSchemaData?.forEach((schema) => {
        if (!sheetSchemas?.find((s) => s.id === schema.id)) {
          deletedSheetSchemaIds.push(schema.id);
        }
      });

      // 直前に作成されたsheetSchemaは削除対象から除外する
      deletedSheetSchemaIds = deletedSheetSchemaIds.filter(
        (id) => !createSheetSchemaData?.find((schema) => schema.id === id)
      );

      const { error: deleteSheetSchemaError } = await supabase
        .from("sheet_schema")
        .delete()
        .in("id", deletedSheetSchemaIds || []);

      if (deleteSheetSchemaError) {
        throw new Error(deleteSheetSchemaError.message);
      }

      setShouldSave(false);

      toast({
        description: "正常に保存されました。",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      toast({
        title: "エラー",
        description: "保存できませんでした。時間をおいて再度お試しください。",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
      Sentry.captureException(error);
    }
  };

  const onClickSaveSchemaSetting: SubmitHandler<IFormInput> = async (data) => {
    if (!project) return;

    const insertRequestBody: Partial<SheetSchema>[] = [];
    const upsertRequestBody: Partial<SheetSchema>[] = [];

    data.items.forEach((item, index) => {
      const schema = sheetSchemas?.find((schema) => schema.id === item.id);

      if (schema) {
        upsertRequestBody.push({
          id: schema.id,
          project_id: schema.project_id,
          title: item.colName,
          // data_type: item.colDataType,
          is_required: item.isRequired,
          column_index: index,
          sheet_id: activeSheetId,
        });
        return;
      }

      insertRequestBody.push({
        project_id: project.id,
        title: item.colName,
        // data_type: item.colDataType,
        is_required: item.isRequired,
        column_index: index,
        sheet_id: activeSheetId,
      });
    });

    try {
      const { error: upsertError } = await supabase
        .from("sheet_schema")
        .upsert(upsertRequestBody, { onConflict: "id" });

      if (upsertError) {
        throw new Error(upsertError.message);
      }

      const { error: insertError } = await supabase
        .from("sheet_schema")
        .insert(insertRequestBody);

      if (insertError) {
        throw new Error(insertError.message);
      }

      const savedSheetSchema = await fetchSheetSchemas(project.id);

      if (!univerRef.current || !savedSheetSchema) return;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      const univerAPI = FUniver.newAPI(univerRef.current.getUniverRef());

      const unitId = "workbook";

      univerAPI.registerSheetColumnHeaderExtension(
        unitId,
        new ColumnHeaderCustomExtension(savedSheetSchema)
      );

      onSchemaSettingDrawerClose();

      toast({
        description: "正常に保存されました。",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      toast({
        description: "保存できませんでした。時間をおいて再度お試しください。",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
      Sentry.captureException(error);
    }
  };

  const buildSchemaSettingDefualtValue = (
    activeSheetId: string,
    workbookDataSnapshot: IWorkbookData,
    sheetSchemas?: SheetSchema[]
  ) => {
    let columnCount = 0;
    Object.keys(workbookDataSnapshot.sheets).forEach((sheetName: string) => {
      const sheet = workbookDataSnapshot.sheets[sheetName];
      if (sheet.id !== activeSheetId) return;

      columnCount = sheet.columnCount as number;
    });

    const defaultValues: IFormInput = {
      items: [],
    };

    for (let i = 0; i < columnCount; i++) {
      const schema = sheetSchemas?.find(
        (schema) =>
          schema.column_index === i && schema.sheet_id === activeSheetId
      );

      defaultValues.items.push({
        id: schema?.id || 0,
        colName: `${schema?.title || numberToABC(i)}`,
        // colDataType: schema?.data_type || "",
        isRequired: schema?.is_required || false,
      });
    }

    return defaultValues;
  };

  useEffect(() => {
    const fetchSheetProject = async () => {
      try {
        const { data, error } = await supabase
          .from("project")
          .select("*")
          .eq("id", params.projectId)
          .single();

        if (error) {
          throw new Error(error.message);
        }

        setProject(data);
        setWorkbookData(data.workbook_data);
        setWorkbookDataSnapshot(data.workbook_data);

        return data;
      } catch (error) {
        toast({
          description:
            "データを取得できませんでした。時間をおいて再度お試しください。",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
        Sentry.captureException(error);
      }
    };

    // FIXME: 同じAPIを複数回呼んでしまっている。
    // SheetProjectなどを取得する → シートがレンダリングされる → activeSheetIdが設定される → 再度、SheetProjectなどを取得しようとする
    (async () => {
      const sheetProject = await fetchSheetProject();
      if (!sheetProject) return;

      fetchSheetSchemas(sheetProject.id);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.projectId]);

  useEffect(() => {
    if (!project || !workbookData) return;

    methods.setValue(
      "items",
      buildSchemaSettingDefualtValue(
        activeSheetId,
        project.workbook_data as unknown as IWorkbookData,
        sheetSchemas
      ).items
    );

    setIsSchemaViolated(
      checkIsSchemaViolated(
        sheetSchemas || [],
        workbookDataSnapshot as IWorkbookData,
        setInvalidCells
      )
    );
  }, [
    activeSheetId,
    methods,
    project,
    sheetSchemas,
    workbookData,
    workbookDataSnapshot,
  ]);

  if (!project || !sheetSchemas || !workbookData || !workbookDataSnapshot) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  return (
    <Box>
      <Helmet>
        <title>{project.title}</title>
      </Helmet>
      <Box
        h="100vh"
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box p={4}>
          <Flex align="center">
            <Box w="40px" onClick={() => navigate("/")}>
              <img src={KurageIcon} className="App-logo" alt="logo" />
            </Box>
            <Input
              value={project.title || ""}
              onChange={handleTitleChange}
              variant="outline"
              bg="white"
              color="black"
              size="md"
              maxWidth="400px"
              fontSize="24"
              borderColor="gray.300"
            />
            <Box ml="auto" mr="4">
              <HStack>
                {isSchemaViolated && (
                  <Button
                    leftIcon={<WarningIcon boxSize={6} color="red.500" />}
                    aria-label="warning"
                    bgColor="red.100"
                    _hover={{ bgColor: "red.200" }}
                    onClick={() => onSchemaAlertDrawerOpen()}
                  >
                    ルール違反
                  </Button>
                )}

                <Button
                  w="120"
                  leftIcon={<RiSettings5Fill />}
                  onClick={() => onSchemaSettingDrawerOpen()}
                  // 値の変更がある場合には、列の移動・削除・追加なども行われた可能性があるが、
                  // 列の操作に合わせたルール設定メニューのデータの入れ替えはまだ対応していないので、ルール設定ボタンを無効化する。
                  isDisabled={shouldSave}
                >
                  ルール設定
                </Button>
                <Button
                  w="120"
                  leftIcon={<FaSave />}
                  colorScheme="teal"
                  onClick={() => handleOnClickSave()}
                  isDisabled={!shouldSave || isSchemaViolated}
                >
                  保存
                </Button>
              </HStack>
            </Box>
          </Flex>
        </Box>
        <FormProvider {...methods}>
          <SchemaSettingDrawer
            isOpen={isSchemaSettingDrawerOpen}
            onClose={onSchemaSettingDrawerClose}
            onSubmit={methods.handleSubmit(onClickSaveSchemaSetting)}
            fields={fields}
            project={project}
          />
        </FormProvider>
        <SchemaAlertDrawer
          isOpen={isSchemaAlertDrawerOpen}
          onClose={onSchemaAlertDrawerClose}
          invalidCells={invalidCells}
        />
        <UniverSheet
          ref={univerRef}
          data={workbookData}
          sheetProject={project}
          sheetSchemas={sheetSchemas}
          handleExecuteCommand={handleExecuteCommand}
          handleMoveCol={handleMoveCol}
          handleInsertCol={handleInsertCol}
          handleRemoveCol={handleRemoveCol}
          setSheetSchema={setSheetSchemas}
          setActiveSheetId={setActiveSheetId}
        />
      </Box>
    </Box>
  );
}
