import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  TableRowProps,
  Icon,
  HStack,
  Text,
  Box,
  Heading,
  Card,
  CardHeader,
  CardBody,
  Center,
  Button,
  Spinner,
  UnorderedList,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useToast,
  ListItem,
  Link,
} from "@chakra-ui/react";
import SidebarWithHeader from "../../SidebarWithHeader";
import { FC, useEffect, useState } from "react";
import { LuFileSpreadsheet } from "react-icons/lu";
import { FaPlus } from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import { supabase } from "../../../supabaseClient";
import { useNavigate } from "react-router-dom";
import { Project } from "../../../../supabase/schema/types";
import * as Sentry from "@sentry/react";

const formatDate = (dateString: string): string => {
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2); // 月は0から始まるため+1
  const day = ("0" + date.getDate()).slice(-2);
  const hours = ("0" + date.getHours()).slice(-2);
  const minutes = ("0" + date.getMinutes()).slice(-2);

  return `${year}/${month}/${day} ${hours}:${minutes}`;
};

const BodyTr: FC<TableRowProps> = (props) => {
  return <Tr _hover={{ bg: "gray.100" }} {...props} />;
};

type ConfirmationDeleteModalComponentProps = {
  isOpen: boolean;
  onClose: () => void;
  handleOnClickDelete: () => void;
  isProjectDeleting: boolean;
};

const ConfirmationDeleteModal: FC<
  ConfirmationDeleteModalComponentProps
> = (props: {
  isOpen: boolean;
  onClose: () => void;
  handleOnClickDelete: () => void;
  isProjectDeleting: boolean;
}) => {
  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>削除の確認</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          一度削除されたプロジェクトは復元できません。
          <br />
          本当に削除しますか？
        </ModalBody>

        <ModalFooter>
          {props.isProjectDeleting ? (
            <IconButton
              aria-label="loading"
              icon={<Spinner />}
              isLoading
              colorScheme="red"
            />
          ) : (
            <Button
              backgroundColor="red.500"
              color="white"
              _hover={{ backgroundColor: "red.600" }}
              onClick={props.handleOnClickDelete}
            >
              削除
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default function Dashboard() {
  const [projects, setProjects] = useState<Project[]>([]);
  const navigate = useNavigate();
  const [isFetchingSheetProjects, setIsFetchingSheetProjects] = useState(false);
  const {
    isOpen: isConfirmationDeleteModalOpen,
    onOpen: onConfirmationDeleteModalOpen,
    onClose: onConfirmationDeleteModalClose,
  } = useDisclosure();
  const [deleteTargetProjectId, setDeleteTargetProjectId] =
    useState<string>("");
  const [isProjectDeleting, setIsProjectDeleting] = useState(false);
  const toast = useToast();

  const fetchProjects = async () => {
    setIsFetchingSheetProjects(true);
    try {
      const { data, error } = await supabase.from("project").select("*");

      if (error) {
        throw new Error(error.message);
      }

      setProjects(data);
    } catch (error) {
      toast({
        title: "エラー",
        description:
          "プロジェクトを取得できませんでした。時間をおいて再度お試しください。",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
      Sentry.captureException(error);
    }
    setIsFetchingSheetProjects(false);
  };

  const handleCreateProject = async () => {
    try {
      const { data: createProjectData, error: createProjectError } =
        await supabase.from("project").insert({}).select().single();

      if (createProjectError) {
        throw new Error(createProjectError.message);
      }

      const { error: createProjectCreatorError } = await supabase
        .from("project_creator")
        .insert({
          project_id: createProjectData.id,
          user_id: (await supabase.auth.getUser()).data.user?.id,
        });

      if (createProjectCreatorError) {
        throw new Error(createProjectCreatorError.message);
      }

      const { error: createProjectUserError } = await supabase
        .from("project_user")
        .insert({
          project_id: createProjectData.id,
          user_id: (await supabase.auth.getUser()).data.user?.id,
        });

      if (createProjectUserError) {
        throw new Error(createProjectUserError.message);
      }

      navigate(`/project/${createProjectData.id}`);
    } catch (error) {
      toast({
        title: "エラー",
        description:
          "プロジェクトを作成できませんでした。時間をおいて再度お試しください。",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
      Sentry.captureException(error);
    }
  };

  const handleOnClickDelete = async () => {
    setIsProjectDeleting(true);

    try {
      const { error: deleteProjectError } = await supabase
        .from("project")
        .delete()
        .eq("id", deleteTargetProjectId);

      if (deleteProjectError) {
        throw new Error(deleteProjectError.message);
      }

      fetchProjects();

      toast({
        description: "正常に削除されました。",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "エラー",
        description:
          "プロジェクトを削除できませんでした。時間をおいて再度お試しください。",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
      onConfirmationDeleteModalClose();
      setIsProjectDeleting(false);
      Sentry.captureException(error);
    }
    onConfirmationDeleteModalClose();
    setIsProjectDeleting(false);
  };

  useEffect(() => {
    fetchProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SidebarWithHeader
        homeChildren={
          <TableContainer bg="white" h="100%" p="16">
            <Box display="flex" justifyContent="space-between">
              <Text fontSize="30" fontWeight="bold" mb="10">
                プロジェクト一覧
              </Text>
              <Button
                leftIcon={<FaPlus />}
                onClick={handleCreateProject}
                bg="blue.400"
                color="white"
                _hover={{ bg: "blue.600" }}
              >
                作成
              </Button>
            </Box>
            {isFetchingSheetProjects ? (
              <Center>
                <Spinner />
              </Center>
            ) : (
              <Table variant="simple" size="sm">
                <Thead>
                  <Tr>
                    <Th>プロジェクト名</Th>
                    <Th>作成日</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {projects &&
                    projects.map((project) => (
                      <BodyTr key={project.id}>
                        {/* FIXME: TdごとにonClickを設定しているのを直したい。 */}
                        <Td onClick={() => navigate(`/project/${project.id}`)}>
                          <HStack>
                            <Icon as={LuFileSpreadsheet} mr="4" boxSize="6" />
                            <Text>{project.title}</Text>
                          </HStack>
                        </Td>
                        <Td onClick={() => navigate(`/project/${project.id}`)}>
                          {formatDate(project.created_at)}
                        </Td>
                        <Td>
                          <Menu>
                            <MenuButton
                              as={IconButton}
                              icon={<BsThreeDotsVertical />}
                              borderRadius="25"
                              w="4"
                              backgroundColor="transparent"
                              _hover={{ bg: "gray.300" }}
                            />
                            <MenuList>
                              <MenuItem
                                color="red.300"
                                fontWeight="bold"
                                onClick={() => {
                                  setDeleteTargetProjectId(project.id);
                                  onConfirmationDeleteModalOpen();
                                }}
                              >
                                削除
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </Td>
                      </BodyTr>
                    ))}
                </Tbody>
                <ConfirmationDeleteModal
                  isOpen={isConfirmationDeleteModalOpen}
                  onClose={onConfirmationDeleteModalClose}
                  handleOnClickDelete={() => {
                    handleOnClickDelete();
                  }}
                  isProjectDeleting={isProjectDeleting}
                />
              </Table>
            )}
          </TableContainer>
        }
        helpChildren={
          <Box p="16">
            <Box>
              <Text fontSize="30" fontWeight="bold">
                ヘルプ
              </Text>
            </Box>
            <Card rounded={"lg"} w="80%" mx="auto" mt="10">
              <CardHeader>
                <Heading size="md">各種資料</Heading>
              </CardHeader>
              <CardBody>
                <UnorderedList>
                  <ListItem>
                    <Link
                      href="https://www.kurage-sales.com/terms-of-service"
                      target="_blank"
                      color="blue.400"
                      textDecoration="underline"
                    >
                      利用規約
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link
                      href="https://www.kurage-sales.com/privacy-policy"
                      target="_blank"
                      color="blue.400"
                      textDecoration="underline"
                    >
                      プライバシーポリシー
                    </Link>
                  </ListItem>
                </UnorderedList>
              </CardBody>
            </Card>
          </Box>
        }
      />
    </>
  );
}
