import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import { ChakraProvider } from "@chakra-ui/react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import * as Sentry from "@sentry/react";

if (process.env.NODE_ENV === "production")
  Sentry.init({
    dsn: "https://22e1adf74713767bfdc7fd2bcd3864e0@o4507955341557760.ingest.us.sentry.io/4507955344244736",
    integrations: [],
  });

ReactDOM.createRoot(document.getElementById("root")!).render(
  // UniverがStrictModeに対応していないため、コメントアウト
  // <React.StrictMode>
  <HelmetProvider>
    <ChakraProvider>
      <Helmet>
        <title>Kurage ベータ版</title>
      </Helmet>
      <App />
    </ChakraProvider>
  </HelmetProvider>
  // /React.StrictMode>
);
