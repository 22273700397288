import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  Spinner,
  Center,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { supabase } from "../../../supabaseClient";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";

type FormValues = {
  email: string;
  password: string;
};

export default function SignIn() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();
  const toast = useToast();
  const colorModeValue = useColorModeValue("white", "gray.700");

  const onSubmit = async (formValues: FormValues) => {
    setIsSubmitting(true);
    try {
      const { error } = await supabase.auth.signInWithPassword({
        email: formValues.email,
        password: formValues.password,
      });

      if (error) {
        throw new Error(error.message);
      }

      navigate("/");
    } catch (error) {
      toast({
        title: "エラー",
        description:
          "サインインに失敗しました。メールアドレス・パスワードをご確認ください。",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    (async () => {
      const {
        data: { session: session },
        error,
      } = await supabase.auth.getSession();

      if (error) {
        console.error(error);
        return;
      }

      if (session) {
        navigate("/");
        return;
      }

      setIsLoading(false);
    })();
  }, [navigate, setIsLoading]);

  if (isLoading) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  return (
    <Flex align={"center"} justify={"center"}>
      <Stack spacing={8} mx={"auto"} minW={"60%"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <Heading fontSize={"4xl"}>
            Kurage
            <span
              style={{
                marginTop: "1.0em",
                fontSize: "0.5em",
                marginLeft: "0.5em",
                fontWeight: "bold",
              }}
            >
              ベータ版
            </span>
          </Heading>
          <Text fontSize={"lg"} color={"gray.600"}>
            ログイン
          </Text>
        </Stack>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            rounded={"lg"}
            bg={colorModeValue}
            boxShadow={"lg"}
            p={8}
            maxW={"2xl"}
            mx={"auto"}
          >
            <Stack spacing={4}>
              <FormControl id="email" mb={2}>
                <FormLabel>メールアドレス</FormLabel>
                <Input
                  {...register("email", {
                    required: "この項目は必須入力です。",
                  })}
                  type="email"
                  autoComplete="email"
                />

                {errors.email && (
                  <Text color="red">{errors.email.message?.toString()}</Text>
                )}
              </FormControl>

              <FormControl id="password" mb={10}>
                <FormLabel>パスワード</FormLabel>
                <Input
                  {...register("password", {
                    required: "この項目は必須入力です。",
                    minLength: {
                      value: 8,
                      message: "パスワードは8文字以上で入力してください。",
                    },
                  })}
                  type="password"
                  autoComplete="current-password"
                />

                {errors.password && (
                  <Text color="red">{errors.password.message?.toString()}</Text>
                )}
              </FormControl>

              <Stack spacing={10}>
                <Button
                  bg={"blue.400"}
                  color={"white"}
                  _hover={{
                    bg: "blue.500",
                  }}
                  type="submit"
                  isLoading={isSubmitting}
                >
                  ログイン
                </Button>
              </Stack>
            </Stack>
            <Text
              align={"center"}
              fontSize={"sm"}
              mt={4}
              _hover={{ color: "blue.400" }}
            >
              <a href="/sign-up" color={"blue.400"}>
                新規登録はこちら
              </a>
            </Text>
          </Box>
        </form>
      </Stack>
    </Flex>
  );
}
