import { Box, Center, Spinner } from "@chakra-ui/react";
import { useEffect } from "react";
import { supabase } from "../../../supabaseClient";
import { useNavigate } from "react-router-dom";

export default function SignOut() {
  const navigate = useNavigate();

  useEffect(() => {
    const signOut = async () => {
      await supabase.auth.signOut();
    };
    signOut();
    navigate("/sign-in");
  }, [navigate]);

  return (
    <Box>
      <Center>
        <Spinner />
      </Center>
    </Box>
  );
}
